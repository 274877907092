import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="err-wrap">
      <img
        src={require("../assets/404.webp")}
        alt="cryptocurrency exchange development company"
        className="err-img"
      />
      <h1 className="err-title">Page Not Found</h1>
      <div className="err-btn-wrap">
        <Link className="err-btn" to="/">
          Go Home
        </Link>
      </div>
    </div>
  );
};

export default Error;
