import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import { ToastContainer } from "react-toastify";
import Error from "./pages/Error";
// import RemoveTrailingCharacters from "./pages/RemoveTrailingCharacters";

function App() {
  return (
    <>
      <BrowserRouter>
        {/* <RemoveTrailingCharacters /> */}
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
